var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dashboard-card',{attrs:{"titleCard":_vm.$t('sidebar.cancelledProjects')}},[_c('div',{staticClass:"table-dashboard temporary-table"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersUserManagement,"items":_vm.dataUserManagement,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","item-key":"name"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"dark":"","small":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" مشاهده پروژه نهایی ")])]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"ma-auto",attrs:{"max-height":"40","max-width":"40","src":item.icon}})]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center service-table"},[_c('div',[_c('img',{attrs:{"src":item.title.src,"alt":""}})]),_c('div',{staticClass:"d-flex flex-column align-start px-4"},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"error","depressed":"","small":""}},[_vm._v(" featured ")]),_c('div',{staticClass:"m-0 my-1 service-table-title"},[_vm._v(_vm._s(item.title.title))]),_c('div',{staticClass:"m-0"},[_vm._v(" قیمت به ریال از: "),_c('strong',[_vm._v(_vm._s(item.title.price))])])],1)])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center service-table"},[_c('div',{staticClass:"d-flex flex-column align-start px-4"},[_c('div',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm._f("readMore")(item.description,15, '...'))+" ")])])])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center service-table"},[_c('div',{staticClass:"d-flex flex-column align-start px-4"},[_c('div',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm._f("changeDate")(item['updated_at']))+" ")])])])]}},{key:"item.serviceStatus",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticClass:"col-md-8 my-5",attrs:{"items":item.serviceStatus,"append-outer-icon":"mdi-check-underline-circle","menu-props":"auto","hide-details":"","label":"انتخاب وضعیت","single-line":"","solo":""}})]}}])}),(_vm.dataUserManagement.length !== 0)?_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1),(!_vm.dataUserManagement)?_c('div',{staticClass:"text-center text-h6 my-3"},[_vm._v(" هیچ پروژه ای یافت نشد ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }